import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../TitleIzquierda";
import { listInfoData } from "../../constants/listInfoData";

import "../../styles/components/objetoSocial.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Social() {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [maxHeight, setMaxHeight] = useState("auto");
  
  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  
    // Función para calcular la altura máxima
    const calculateMaxHeight = () => {
      // Encuentra la altura máxima entre los valoresData
      const maxCardHeight = listInfoData.reduce((maxHeight, data) => {
        let cardTextHeight = data.objeto.length;
  
        // Verificar si la pantalla es mayor a 767px
        if (window.innerWidth >= 1400) {
          cardTextHeight = data.objeto.length * 1.1;
        } else if (window.innerWidth < 1400 && window.innerWidth >= 1200){
          cardTextHeight = data.objeto.length * 1.25;
        } else if (window.innerWidth < 1200 && window.innerWidth >= 992){
          cardTextHeight = data.objeto.length * 1.1;
        } else if (window.innerWidth < 360){
          cardTextHeight = data.objeto.length * 1.50;
        }
  
        return cardTextHeight > maxHeight ? cardTextHeight : maxHeight;
      }, 0);
  
      setMaxHeight(`${maxCardHeight}px`); // Establece la altura máxima en píxeles
    };
  
    // Llama a calculateMaxHeight() cuando se carga la página
    calculateMaxHeight();
  
    // Agrega un event listener para el evento 'resize'
    window.addEventListener("resize", calculateMaxHeight);
  
    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", calculateMaxHeight);
    };
  }, [control, inView]);

  return (
    <motion.div className="objetoSocial box" ref={ref} variants={boxVariant} initial="hidden" animate={control}>
      <Title titulo="Objeto social" scroll={inView}/>
      <Container className="pt-5">
        <Row xs={1} sm={1} md={2} lg={3} xl={4} className="g-4">
          {listInfoData.map((data, index) => (
            <Col key={index} className="p-2">
              <Card>
                <Card.Img className="icon" variant="top" src={data.icon} alt={data.alt} height="100%"/>
                <Card.Body style={{ height: maxHeight }}>
                  <Card.Text>
                    {data.objeto}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row> 
      </Container>
    </motion.div>
  );
}