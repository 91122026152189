import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ROUTES } from "../../constants/routes";
import Title from "../TitleIzquierda";
import { desarrolloData } from "../../constants/servicioData";

import "../../styles/components/Servicio.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function ColocacionFoley() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <motion.div className="box infoServicios mt-5" ref={ref} variants={boxVariant} initial="hidden" animate={control}>
      <Title titulo={desarrolloData[4].titulo} scroll={inView}/>
      <Container className="mt-5 mb-5">
        <Row className="align-items-center">
          <Col xl={6} lg={6} md={12} className="imagen">
            <img src={desarrolloData[4].imgCarta}  alt={desarrolloData[4].alt} height="100%"/>
          </Col>
          <Col xl={6} lg={6} md={12}>
            {desarrolloData[4].texto}
            <NavLink className="title__regresar" to={ROUTES.REGRESAR}>
              <ArrowBackIcon />
              <Typography variant="body1" fontWeight="400">
                Regresar
              </Typography>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}