export const ROUTES = {
  ROOT: "/",
  HOME: "/home",
  TEAMWORK: "/TeamWork",
  INVESTIGACION: "/Investigacion",
  SERVICIOS: "/Servicios",

  VITALES: "/Vitales",
  CANALIZACION: "/Canalizacion",
  HERIDA: "/Herida",
  PUNTOS: "/Puntos",
  SONDA: "/Sonda",
  MEDICAMENTO: "/Medicamento",
  MUESTRA: "/Muestra",
  GLUCOMETRIA: "/Glucometria",
  TERAPIA: "/Terapia",
  NASOGASTRICA: "/Nasogastrica",
  REGRESAR: "/#servicios"
};

export const ANCLAS = {
  FACEBOOK: "https://www.facebook.com/CuidarteEnColima",
  ENFERMERIA: "/#enfermeria",
  CONOCENOS: "/#conocenos",
  SOCIAL: "/#social",
  SERVICIOS: "/#servicios",
  EQUIPOS: "/#equipos",
};
