import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ROUTES } from "../../constants/routes";
import Title from "../TitleIzquierda";
import { desarrolloData } from "../../constants/servicioData";

import "../../styles/components/Servicio.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function CuracionHeridas() {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [blurImage, setBlurImage] = useState(true);
  const [showSensitiveContent, setShowSensitiveContent] = useState(true);

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  // Función para alternar el desenfoque al hacer clic en el botón
  const toggleBlur = () => {
    setBlurImage(!blurImage);
    setShowSensitiveContent(!showSensitiveContent);
  };

  return (
    <motion.div className="box infoServicios mt-5" ref={ref} variants={boxVariant} initial="hidden" animate={control}>
      <Title titulo={desarrolloData[2].titulo} scroll={inView}/>
      <Container className="mt-5 mb-5">
        <Row className="align-items-center">
          <Col xl={6} lg={6} md={12} className="imagen">
            <div className="positionLeyenda">
              <img src={desarrolloData[2].imgCarta}  alt={desarrolloData[2].alt} className={blurImage ? desarrolloData[2].blur : ""} height="100%"/>
              {showSensitiveContent && (
                <div className="imagen-leyenda contenido-sensible">
                  <p>Contenido Sensible</p>
                  <Button variant="primary" onClick={toggleBlur}>
                    Desenfocar
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col xl={6} lg={6} md={12}>
            {desarrolloData[2].texto}
            <NavLink className="title__regresar" to={ROUTES.REGRESAR}>
              <ArrowBackIcon />
              <Typography variant="body1" fontWeight="400">
                Regresar
              </Typography>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}