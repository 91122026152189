import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Col, Container, Row, Image } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Title from "../../components/TitleDerecha";
import { tareaData } from "../../constants/tareasData";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Tareas(){
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const handleSlideChange = (index) => {
    setCurrentSlide(index); // Función para cambiar el slide actual
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    if (inView) {
      control.start("visible");
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [control, inView]);
  
  return(
    <motion.div className="box" ref={ref} variants={boxVariant} initial="hidden" animate={control} >
      <Title titulo="¿Qué dicen nuestros enfermeros" scroll={inView}/>
      <div className="text-justify pt-5 pb-5">
        <Container>
          <p>Como enfermero profesional, cubriré las necesidades físicas de un paciente asistiendo en las siguientes tareas:</p>
        </Container>
        <Row className="fondos">
          <Carousel
            infiniteLoop={true}
            centerMode={true}
            centerSlidePercentage={isMobile ? 80 : 70}
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={true}
            emulateTouch={true}
            autoFocus={false}
            thumbWidth={100}
            selectedItem={currentSlide}
            interval={2500}
            transitionTime={500}
            swipeScrollTolerance={5}
            onChange={handleSlideChange}
          >
            {tareaData.map(({idTarea, img, alt, imgMovil}) => (
              <div key={idTarea}>
                <Col>
                  {isMobile ? (
                    <Image src={imgMovil} alt={alt} />
                  ) : (
                    <Image src={img} alt={alt} />
                  )}
                </Col>
              </div>
            ))}
          </Carousel>
          <div className="carousel-dots pt-1">
            {tareaData.map(({ idTarea }, index) => (
              <span
                key={idTarea}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => handleSlideChange(index)}
              />
            ))}
          </div>
        </Row> 
        <Container className="pt-5">        
          <p style={{marginBottom:0}}>En última instancia, <i>mi objetivo como enfermero profesional es proporcionar al paciente la atención que necesita para recuperarse o vivir cómodamente durante sus últimos días.</i> Trabajaré con el paciente y su familia para desarrollar un plan de atención individualizado que satisfaga sus necesidades específicas. Es importante recordar que cada paciente es diferente y tendrá sus propias necesidades. Como enfermero profesional, estaré atento a las necesidades cambiantes del paciente y adaptaré mi atención en consecuencia.</p> 
        </Container>               
      </div>   
    </motion.div>
  );
}