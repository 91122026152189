import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Title from "../TitleIzquierda";
import { enfermeriaData } from "../../constants/enfermeriaData";

import "../../styles/components/Enfermeria.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Enfermeria() {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index); // Función para cambiar el slide actual
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    if (inView) {
      control.start("visible");
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [control, inView]);

  return (
    <motion.div className="box enfermeria" ref={ref} variants={boxVariant} initial="hidden" animate={control}>
      <Title titulo="Enfermería" scroll={inView}/>
      <div className="text-justify pt-5">
        <Container>
          <p>
            ¿Por qué los médicos experimentados recomiendan la asistencia de un enfermero profesional en casa para la recuperación de un paciente o para cuidados paliativos?
          </p>     
        </Container>   
        <Row>
          <Carousel
            infiniteLoop={true}
            centerMode={true}
            centerSlidePercentage={isMobile ? 80 : 70}
            showArrows={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={true}
            emulateTouch={true}
            autoFocus={false}
            thumbWidth={100}
            selectedItem={currentSlide}
            interval={2500}
            transitionTime={500}
            swipeScrollTolerance={5}
            onChange={handleSlideChange}
          >
            {enfermeriaData.map(({ idEnfermeria, img, imgMovil, alt }) => (
              <div key={idEnfermeria}>
                <Col>
                  {isMobile ? (
                    <Image src={imgMovil} alt={alt} height="100%"/>
                  ) : (
                    <Image src={img} alt={alt} height="100%"/>
                  )}
                </Col>
              </div>
            ))}
          </Carousel>
          <div className="carousel-dots pt-1">
            {enfermeriaData.map(({ idEnfermeria }, index) => (
              <span
                key={idEnfermeria}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => handleSlideChange(index)}
              />
            ))}
          </div>
        </Row>
        <Container className="pt-5">
          <p style={{marginBottom: 0}}>
            Por lo tanto, la asistencia de un enfermero profesional en casa puede proporcionar una serie de beneficios para los pacientes que se recuperan de una enfermedad o que reciben cuidados paliativos. Los enfermeros profesionales están altamente cualificados para proporcionar atención médica de alta calidad, aliviar el estrés de los cuidadores familiares y proporcionar apoyo emocional y asesoramiento preciso para mantener en mejores condiciones al paciente, su entorno y el ambiente familiar durante los momentos más apremiantes de un padecimiento.
          </p>
        </Container>
      </div> 
    </motion.div>
  );
}