import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";

import Directivos from "../../components/Personal/Directivos";
import Tareas from "../../components/Personal/Tareas";

import equipo from "../../assets/images/trabajadores/Personal.png";
import video from "../../assets/videos/Cuidarte.mp4";

import "../../styles/components/TeamWork.scss";

export default function Teamwork() {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    let position = 0;
  
    const drawImage = (image, position) => {
      if (ctx && canvas) {
        // Configurar el tamaño del canvas
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight / 1.1;
  
        // Calcular las coordenadas y tamaño de la imagen
        const scale = Math.min(canvas.width / image.width, canvas.height / image.height) / 3;
        const imageWidth = image.width * scale;
        const imageHeight = image.height * scale;
        const x = position * (canvas.width - imageWidth) /1.05;
        const y = (canvas.height - imageHeight) / 1.25;
  
        // // Dibujar un fondo en el lienzo
        // ctx.fillStyle = '#2E3A4D';
        // ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        // Dibujar la imagen en el lienzo
        ctx.drawImage(image, x, y, imageWidth, imageHeight);
      }
    };
  
    // Cargar la imagen que deseas dibujar
    const image = document.createElement('img');
    image.src = `${equipo}`; // Reemplaza con la ruta de tu imagen
  
    // animacion de transicion de la imagen
    image.onload = () => {        
      const animate = () => {
        position += 0.01; // Ajusta la velocidad de avance de la imagen
  
        drawImage(image, position);
  
        if (position < 1) {
          requestAnimationFrame(animate);
        }
      };
  
      animate();
    };

    const handleResize = () => {
      // Volver a dibujar la imagen cuando cambie el tamaño de la pantalla
      drawImage(image, position);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="Teamwork">
      <Box className="hero mb-5">
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <canvas ref={canvasRef} className="canvas" />
      </Box>
      <Directivos/>
      <Tareas/>
    </div>
  );
}