import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { TitleProps } from "../models/types/TitleProps.type";

import gifDerecho from "../assets/images/derblanco.gif";

import "../styles/components/TitleDerecha.scss";

export default function TitleDerecha({ titulo, scroll }:TitleProps) {
  const transforms = [
    {
      startMatrix: [-1, 0, 0.78129, 1, 0, 0],
      endMatrix: [-1, 0, 0.78129, 1, -20, 0],
    },
    {
      startMatrix: [-1, 0, 0.78129, 1, 0, 0],
      endMatrix: [-1, 0, 0.78129, 1, -40, 0],
    },
    {
      startMatrix: [-1, 0, 0.78129, 1, 0, 0],
      endMatrix: [-1, 0, 0.78129, 1, -60, 0],
    },
  ];
  
  const [transformValues, setTransformValues] = useState(transforms.map(() => "matrix(0, 0, 0, 0, 0, 0)"));

  useEffect(() => {
    if (scroll) {
      transforms.forEach((transform, index) => {
        const { startMatrix, endMatrix } = transform;
        const duration = 1500;
        const interval = 10;
        const steps = Math.ceil(duration / interval);
        const stepValues = startMatrix.map((value, i) => (endMatrix[i] - value) / steps);

        let currentStep = 0;
        const timeout = setInterval(() => {
          currentStep++;

          const matrixValues = startMatrix.map((value, i) => value + stepValues[i] * currentStep);
          const newTransformValue = `matrix(${matrixValues.join(",")})`;

          setTransformValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = newTransformValue;
            return newValues;
          });

          if (currentStep === steps) {
            clearInterval(timeout);
          }
        }, interval);

        return () => {
          clearInterval(timeout);
        };
      });
    }    
  }, [scroll]);

  return(
    <div className="TitleDerecha">
      <div className="capa-inferior" data-anim="group"/>
      <section className="anonce-block">
        <ul className="anonce-block__parts">
          <li className="anonce-block__part" style={{ transform: transformValues[0]}}></li>
          <li className="anonce-block__part" style={{ transform: transformValues[1]}}></li>
          <li className="anonce-block__part" style={{ transform: transformValues[2]}}></li>
        </ul>
        <img src={gifDerecho} className="gif" alt="Mi GIF" />
        <Container>
          <div className="anonce-block__container container capa-superior">
            <div className="anonce-block__body">
              <span className="anonce-block__title anonce-block__title_smaller" data-anim-stagger="fade-top" data-mob-anim="group" data-mob-anim-stagger="fade-top">
                {titulo}
              </span>
            </div>
          </div>
        </Container>
      </section>
    </div>
  )
}