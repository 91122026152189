import { IEnfermeria } from "../models/interfaces/IEnfermeria";

import enfermeria1 from "../assets/images/enfermeria/enfermeriaCompu1.jpg";
import enfermeria2 from "../assets/images/enfermeria/enfermeriaCompu2.jpg";
import enfermeria3 from "../assets/images/enfermeria/enfermeriaCompu3.jpg";
import enfermeria4 from "../assets/images/enfermeria/enfermeriaCompu4.jpg";
import enfermeria5 from "../assets/images/enfermeria/enfermeriaCompu5.jpg";
import enfermeria6 from "../assets/images/enfermeria/enfermeriaCompu6.jpg";

import enfermeriaMovil1 from "../assets/images/enfermeria/enfermeriaMovil1.jpg";
import enfermeriaMovil2 from "../assets/images/enfermeria/enfermeriaMovil2.jpg";
import enfermeriaMovil3 from "../assets/images/enfermeria/enfermeriaMovil3.jpg";
import enfermeriaMovil4 from "../assets/images/enfermeria/enfermeriaMovil4.jpg";
import enfermeriaMovil5 from "../assets/images/enfermeria/enfermeriaMovil5.jpg";
import enfermeriaMovil6 from "../assets/images/enfermeria/enfermeriaMovil6.jpg";

export const enfermeriaData: IEnfermeria[] = [
  //enfermeria1
  {
    img: `${enfermeria1}`,
    imgMovil: `${enfermeriaMovil1}`,
    alt: "enfermeria1",
    idEnfermeria: 1,
  },

  //enfermeria2
  {
    img: `${enfermeria2}`,
    imgMovil: `${enfermeriaMovil2}`,
    alt: "enfermeria2",
    idEnfermeria: 2,
  },

  //enfermeria3
  {
    img: `${enfermeria3}`,
    imgMovil: `${enfermeriaMovil3}`,
    alt: "enfermeria3",
    idEnfermeria: 3,
  },  
  
  //enfermeria4
  {
    img: `${enfermeria4}`,
    imgMovil: `${enfermeriaMovil4}`,
    alt: "enfermeria4",
    idEnfermeria: 4,
  },  

  //enfermeria5
  {
    img: `${enfermeria5}`,
    imgMovil: `${enfermeriaMovil5}`,
    alt: "enfermeria5",
    idEnfermeria: 5,
  },  

  //enfermeria6
  {
    img: `${enfermeria6}`,
    imgMovil: `${enfermeriaMovil6}`,
    alt: "enfermeria6",
    idEnfermeria: 6,
  },  
];