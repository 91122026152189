import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import logoWhats from "../../assets/images/logoWhats.png";

export default function WhatsApp() {
  return (
    <FloatingWhatsApp
      accountName="CuidArte"
      phoneNumber="+523121152377"
      darkMode={true}
      chatMessage="Hola, ¿En que podemos ayudarte?"
      statusMessage="Horario de respuesta 24/7"
      avatar={logoWhats}
      allowClickAway={true}
      messageDelay={0}
      placeholder="Mensaje"
    />
  );
}
