import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router";

import Info from "../../components/Home/InfoEmpresa";
import Equipos from "../../components/Home/Equipos";
import Servicio from "../../components/Home/Servicio";
import Social from "../../components/Home/Social";
import Enfermeria from "../../components/Home/Enfermeria";
import Principal from "../../components/Home/Principal";

export default function Home() {
  const divRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#servicios')
    {
      setTimeout(() => {
        if (divRef.current)
        {
          divRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 700)
    }
  }, [location.hash])

  return (    
    <>  
      <div id="principal" style={{marginBottom: "3rem"}}></div> 
      <Principal/>
      <div id="enfermeria" style={{marginBottom: "3rem"}}></div> 
      <Enfermeria/>
      <div id="conocenos" style={{marginBottom: "3rem"}}></div> 
      <Info />
      <div id="social" style={{marginBottom: "3rem"}}></div> 
      <Social/>
      <div id="servicios" style={{marginBottom: "3rem"}} ref={divRef}></div>
      <Servicio/>
      <div id="equipos" style={{marginBottom: "3rem"}}></div>
      <Equipos />
    </>
  );
}
