import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import { ROUTES } from "../../constants/routes";

import logo from "../../assets/images/CuidArte.png";

import "../../styles/components/Header.scss";

export default function Header() {
  return (
    <div className="Header mb-5">
      <Navbar className="navmenu scroll px-3" expand="lg" variant="dark" fixed="top" collapseOnSelect>
        <Container fluid>
          <Navbar.Brand as={Link} to={ROUTES.ROOT}>
            <img src={logo} className='logo' alt="Logo CuidArte" height="100%"/>
          </Navbar.Brand>
          <Navbar.Toggle/>
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navmenu__nav-items">
              <Nav.Link as={NavLink} to={ROUTES.ROOT}>
                Inicio
              </Nav.Link>
              <Nav.Link as={NavLink} to={ROUTES.TEAMWORK}>
                Personal
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}