import React, { useEffect } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import Title from "../TitleIzquierda";
import { equipoData } from "../../constants/equipoData";

import "../../styles/components/Equipo.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Equipos() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (    
    <motion.div
      className="box equipo"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      <Title titulo="Equipo médico en venta y renta" scroll={inView} />
      <Container className="pt-5 pb-5">
        <Row>
          <p>
            Nuestra meta en el campo de enfermería es proporcionar atención de calidad superior, enfocándonos en la seguridad, la eficiencia y la atención personalizada. Nos esforzamos por ser líderes en el cuidado de la salud y seguir elevando los estándares de excelencia en el ámbito de la enfermería.
          </p>
        </Row>
        <Row className="fondos" >
          {equipoData.map((data) => (
            <Col className={`fondo `} key={data.idEquipo}>
              <Image
                src={data.img}
                alt={data.alt}
                height="100%"
              />
              <figcaption>
                <div className="titulo">{data.titulo}</div>
                <div className="texto">{data.texto}</div>
              </figcaption>
            </Col>
          ))}
        </Row>
      </Container>
    </motion.div>
  );
}