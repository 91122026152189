import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";

import Title from "../TitleDerecha";
import TitleText from "../Title"
import { valoresData } from "../../constants/valoresData";

import mision from "../../assets/images/valores/Mision.jpg";
import vision from "../../assets/images/valores/Vision.jpg";

import "../../styles/components/InfoEmpresa.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function InfoEmpresa() {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [maxHeight, setMaxHeight] = useState("auto");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const paginationRef = useRef(null);

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  
    // Función para calcular la altura máxima
    const calculateMaxHeight = () => {
      // Encuentra la altura máxima entre los valoresData
      const maxCardHeight = valoresData.reduce((maxHeight, data) => {
        let cardTextHeight = data.valor.length * 1.30;
  
        // Verificar si la pantalla es mayor a 767px
        if (window.innerWidth > 767) {
          cardTextHeight = data.valor.length * 1.25;
        }
  
        return cardTextHeight > maxHeight ? cardTextHeight : maxHeight;
      }, 0);
  
      setMaxHeight(`${maxCardHeight}px`); // Establece la altura máxima en píxeles
    };
  
    // Llama a calculateMaxHeight() cuando se carga la página
    calculateMaxHeight();
  
    // Agrega un event listener para el evento 'resize'
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
      calculateMaxHeight();
    };
  
    window.addEventListener("resize", handleResize);
  
    // Limpia los event listeners cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [control, inView, setIsMobile]);

  return (
    <motion.div className="InfoEmpresa box" ref={ref} variants={boxVariant} initial="hidden" animate={control}>
      <Title titulo="¿Quiénes somos?" scroll={inView}/>
      <Container className="pt-5">
        <div className="text-justify">
          <p>
            <span style={{color: "#E7183B"}}>Cuid</span><span style={{color: "#2F6DBF"}}><b>Arte</b></span> nace con el propósito de entregar un servicio de enfermería en el domicilio del paciente, cuando este ya no requería seguir hospitalizado pero aún necesitaba cuidados especializados. Buscamos que este proyecto logre tener un amplísimo margen para que los mejores valores del ser humano florezcan.
          </p>
          <p>
            Queremos diferenciarnos por la calidad y calidez en el servicio, por el profesionalismo y la honestidad, e incluso por ser una empresa formalmente constituida para atender a sus seres queridos cuando son más vulnerables.
          </p>

          <Row className="mt-5">
            <TitleText titulo="Misión y Visión" />
          </Row>
          <Row className={`m-3 d-flex align-items-center${isMobile ? 'flex-direction-column' : ""}`}>
            <Col xl={2} lg={2} className={`p-2 ${isMobile ? "text-center":""}`}>
              <Image src={mision} width={150} height="100%" alt="Misión"/>
            </Col>
            <Col xl={4} lg={4} className="p-3">
              Servir a los pacientes y a sus familias en el proceso de recuperación, cuidando su integridad física, su estado de ánimo y, en lo posible, su comodidad.
            </Col>
            <Col xl={2} lg={2} className={`p-2 ${isMobile ? "text-center":""}`}>
              <Image src={vision} width={150}  height="100%" alt="Visión"/>
            </Col>  
            <Col xl={4} lg={4} className="p-3">
              Trabajar con todas nuestras capacidades para ser la mejor empresa de servicio de enfermería de México
            </Col>                      
          </Row>

          <TitleText titulo="Valores" />
          <Swiper
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{ el: paginationRef.current, clickable: true }}
            spaceBetween={30}
            navigation={true}
            loop={true}
            modules={[Navigation, Autoplay, Pagination]}
            className="mySwiper mt-3 mb-3"
          >
            {valoresData.map((data) => (
              <div key={data.idValor}>
                <SwiperSlide>
                  <Card className="m-1">
                    <Card.Img variant="top" src={data.img} alt={data.alt} height="100%"/>
                    <Card.Body style={{ height: maxHeight }}>
                      <Card.Title className="titulo">{data.titulo}</Card.Title>
                      <Card.Text className="texto">{data.valor}</Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
          <div ref={paginationRef} className="carousel-dots"></div>
        </div>             
      </Container>
    </motion.div>
  );
}