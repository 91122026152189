import React from "react";
import { Col, Row, Nav } from "react-bootstrap";

import { ANCLAS } from "../../constants/routes";

import logoCuidArte from "../../assets/images/LogoCuidArteFooter.png";
import PhoneIcon from "../../assets/images/PhoneIcon.png";
import FacebookIcon from "../../assets/images/FaceIcon.png";

import "../../styles/components/Footer.scss";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <>
      <Row className="footer contentColorCoral d-flex align-items-center p-4">
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="logo">
          <img src={logoCuidArte} alt="Logo" className="labLogo" width={100} height="100%"/>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="telefono">
          <img src={PhoneIcon} alt="Logo" className="telLogo" width={100} height="100%"/>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>          
          <Nav.Link href={ANCLAS.FACEBOOK} target="_blank" className="facebook">
            <img src={FacebookIcon} alt="Logo" className="faceLogo" width={100} height="100%"/>
          </Nav.Link>
        </Col>
      </Row>
      <div className="text-center p-2 text-white" style={{ backgroundColor: "#f0878b" }} >
        MSW Tecnologías S.A. de C.V. {year}
      </div>
    </>
  );
}