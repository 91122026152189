import React from "react";

import { IEmpleado } from "../models/interfaces/IEmpleado";

import directivo1 from "../assets/images/trabajadores/directivo1.jpg";
import directivo2 from "../assets/images/trabajadores/directivo2.jpg";
import directivo3 from "../assets/images/trabajadores/directivo3.jpg";

export const directivoData: IEmpleado[] = [
  {
    id: 1,
    src: `${directivo1}`,
    alt: "Carlos Maldonado Villaverde",
    descripcion: [
      <>
        <div className="text-justify">
          L.C.C. Carlos Maldonado Villaverde.
        </div>
        <div className="text-justify">
          67 años.
        </div>
        <div className="text-justify">
          Licenciado en Ciencias de la Comunicación por el ITESO.
        </div>
        <div className="text-justify">
          Desarrollador de Software.
        </div>
        <div className="text-justify">
          Politólogo.
        </div>
        <div className="text-justify">
          Periodista.
        </div>
        <div className="text-justify">
          Escritor.
        </div>
        <div className="text-justify">
          Actualmente coordina el proyecto <span style={{color: "#E7183B"}}>Cuid</span><span style={{color: "#2F6DBF"}}><b>Arte</b></span> de enfermería en casa.
        </div>
        <div className="text-justify">
          <i>"<span style={{color: "#E7183B"}}>Cuid</span><span style={{color: "#2F6DBF"}}><b>Arte</b></span> me abrió las puertas a un proyecto en el que la empatía y el espíritu de servicio convergen para dar la mejor de las atenciones a quienes se encuentran en situación vulnerable a causa de su salud. Es un orgullo trabajar todos los días con personas que anteponen la comodidad y salud del paciente a sus propias necesidades. La enfermería es -sin duda- la parte más humana de la medicina."</i>
        </div>
      </>
    ],
  },
  {
    id: 2,
    src: `${directivo2}`,
    alt: "Alejandra Jazmín Cordova Cabrera",
    descripcion: [
      <>
        <div className="text-justify">
          Tec. En Enf. Alejandra Jazmín Cordova Cabrera.
        </div>
        <div className="text-justify">
          39 años.
        </div>
        <div className="text-justify">
          Egresada de Conalep generación 2000 - 2003
        </div>
        <div className="text-justify">
          Curso taller: De la urgencia médica a la terapia intensiva: intervención de enfermería al paciente en estado crítico y en la terapia de infusión.
        </div>
        <div className="text-justify">
          Simposio con enfoque de medicina en primer contacto, urgencias médico quirúrgicas.
        </div>
        <div className="text-justify">
          Diplomado de enfermería médico quirúrgica.
        </div>
        <div className="text-justify">
          Diplomado urgencias médico quirúrgica.
        </div>
        <div className="text-justify">
          Participé en jornadas de vacunación del 2002 al 2005 en el IMSSS.
        </div>
        <div className="text-justify">
          Actualmente laboro en clínica Córdoba con una antigüedad de 14 años.
        </div>
        <div className="text-justify">
          <i>"Para mí, <span style={{color: "#E7183B"}}>Cuid</span><span style={{color: "#2F6DBF"}}><b>Arte</b></span> es una empresa enfocada en brindar un servicio de calidad y calidez de un profesional, hacía las personas en estado vulnerable, llevando a cabo todos los cuidados necesarios que conlleva a una mejoría tanto en el estado físico como el anímico."</i>
        </div>
      </>
    ],
  },
  {
    id: 3,
    src: `${directivo3}`,
    alt: "Eréndira Contreras Aguilar",
    descripcion: [
      <>
        <div className="text-justify">
          E.E. Eréndira Contreras Aguilar.
        </div>
        <div className="text-justify">
          51 años. 
        </div>
        <div className="text-justify">
          Licenciada en enfermería egresada de la Universidad de Guadalajara.
        </div>
        <div className="text-justify">
          Especializada en Administración y Docencia.
        </div>
        <div className="text-justify">
          Diplomado en Farmacología.
        </div>
        <div className="text-justify">
          Diplomado en Urgencias Médicas.
        </div>
        <div className="text-justify">
          Diplomado en Cuidado de Heridas, Estomas y Pie Diabético.
        </div>
        <div className="text-justify">
          Actualmente trabajando en el Hospital Regional Universitario de Colima, con 16 años de antigüedad.
        </div>
        <div className="text-justify">
          <i>"Para mí, <span style={{color: "#E7183B"}}>Cuid</span><span style={{color: "#2F6DBF"}}><b>Arte</b></span> es una forma de contribuir con calidad y calidez a los pacientes en casa, con un trato digno y respetuoso, atender al paciente como si fuera parte de mi familia."</i>
        </div>
      </>
    ],
  },
]
