import React, { useEffect, useState } from "react";
import { Image} from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import principalCompu from "../../assets/images/principalCompu.jpg";
import principalMovil from "../../assets/images/principalMovil.jpg";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Princiapl() {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    if (inView) {
      control.start("visible");
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [control, inView]);

  return (
    <motion.div className="box" ref={ref} variants={boxVariant} initial="hidden" animate={control}>   
      {isMobile ? (
        <Image src={principalMovil} width="100%" height="100%" alt="Imagen Principal"/>
      ) : (
        <Image src={principalCompu} width="100%" height="100%" alt="Imagen Principal"/>
      )}
    </motion.div>
  );
}