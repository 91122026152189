import React from "react";
import { IEquipo } from "../models/interfaces/IEquipo";

import uno from "../assets/images/equipos/equipo1.jpg";
import dos from "../assets/images/equipos/equipo2.jpg";
import tres from "../assets/images/equipos/equipo3.jpg";
import cuatro from "../assets/images/equipos/equipo4.jpg";
import cinco from "../assets/images/equipos/equipo5.jpg";
import seis from "../assets/images/equipos/equipo6.jpg";

export const equipoData: IEquipo[] = [
  //Baumanometró
  {
    img: `${uno}`,
    titulo:(
      <p>
        <strong>Baumanometró</strong>
      </p>
    ),
    texto: (
      <p>
        Dispositivo médico utilizado para medir la presión arterial de manera no invasiva.
      </p>
    ),
    alt: "Baumanometró",
    idEquipo: 1,
  },

  //Concentrador de oxígeno
  {
    img: `${dos}`,
    titulo: (
      <p>
        <strong>Concentrador de oxígeno</strong>
      </p>
    ),
    texto: (
      <p>
        Equipo médico que filtra el aire para proporcionar oxígeno enriquecido a pacientes con dificultades respiratorias.
      </p>
    ),
    alt: "Concentrador de oxígeno",
    idEquipo: 2,
  },

  //Glucómetro
  {
    img: `${tres}`,
    titulo:(
      <p>
        <strong>Glucómetro</strong>
      </p>
    ),
    texto: (
      <p>
        Dispositivo para medir los niveles de glucosa en sangre, esencial para el control de diabetes.
      </p>
    ),
    alt: "Glucómetro",
    idEquipo: 3,
  },

  //Estetoscopio
  {
    img: `${cuatro}`,
    titulo:(
      <p>
        <strong>Estetoscopio</strong>
      </p>
    ),
    texto: (
      <>
        <p>
          Herramienta médica básica que permite a los profesionales escuchar los sonidos internos del cuerpo.
        </p>
      </>
    ),
    alt: "Estetoscopio",
    idEquipo: 4,
  },

  //Termómetro Digital
  {
    img: `${cinco}`,
    titulo:(
      <p>
        <strong>Termómetro Digital</strong>
      </p>
    ),
    texto: (
      <>
        <p>
          Dispositivo electrónico utilizado para medir la temperatura corporal o ambiental con precisión.
        </p>
      </>
    ),
    alt: "Termómetro Digital",
    idEquipo: 5,
  },

  //Soporte de suero
  {
    img: `${seis}`,
    titulo:(
      <p>
        <strong>Tripié</strong>
      </p>
    ),
    texto: (
      <>
        <p>
          Equipo médico utilizado para sostener y administrar fluidos intravenosas a los pacientes.
        </p>
      </>
    ),
    alt: "Tripié",
    idEquipo: 6,
  },
];
