import { ITarea } from "../models/interfaces/ITarea";

import administracion from "../assets/images/tareas/administracionCompu.jpg";
import herida from "../assets/images/tareas/heridaCompu.jpg";
import vitales from "../assets/images/tareas/vitalesCompu.jpg";
import sintomas from "../assets/images/tareas/sintomasCompu.jpg";
import asistencia from "../assets/images/tareas/asistenciaCompu.jpg";
import emocional from "../assets/images/tareas/emocionalCompu.jpg";
import sociales from "../assets/images/tareas/socialesCompu.jpg";

import administracionMovil from "../assets/images/tareas/administracionMovil.jpg";
import heridaMovil from "../assets/images/tareas/heridaMovil.jpg";
import vitalesMovil from "../assets/images/tareas/vitalesMovil.jpg";
import sintomasMovil from "../assets/images/tareas/sintomasMovil.jpg";
import asistenciaMovil from "../assets/images/tareas/asistenciaMovil.jpg";
import emocionalMovil from "../assets/images/tareas/emocionalMovil.jpg";
import socialesMovil from "../assets/images/tareas/socialesMovil.jpg";

export const tareaData: ITarea[] = [
  {
    idTarea: 1,
    img: `${administracion}`,
    imgMovil: `${administracionMovil}`,
    alt:"Administración de medicamentos"
  },
  {
    idTarea: 2,
    img: `${herida}`,
    imgMovil: `${heridaMovil}`,
    alt:"Atención a las heridas"
  },
  {
    idTarea: 3,
    img: `${vitales}`,
    imgMovil: `${vitalesMovil}`,
    alt:"Monitorización de los signos vitales"
  },
  {
    idTarea: 4,
    img: `${sintomas}`,
    imgMovil: `${sintomasMovil}`,
    alt:"Gestión de los sintomas"
  },
  {
    idTarea: 5,
    img: `${asistencia}`,
    imgMovil: `${asistenciaMovil}`,
    alt:"Asistencia personal"
  },
  {
    idTarea: 6,
    img: `${emocional}`,
    imgMovil: `${emocionalMovil}`,
    alt:"Necesidades emocionales"
  },
  {
    idTarea: 7,
    img: `${sociales}`,
    imgMovil: `${socialesMovil}`,
    alt:"Necesidades sociales"
  },
]