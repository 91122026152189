import React from "react";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Header from "./components/Header/Header";
import HeaderMain from "./components/Header/HeaderMain";
import Footer from "./components/Footer/Footer";
import WhatsApp from "./components/WhatsApp/WhatsApp";
import Loading from "./components/Loading/Loading";
import Home from "./pages/Home/Home";
import TeamWork from "./pages/TeamWork/TeamWork";

import { ROUTES } from "./constants/routes";

import Vitales from "./components/Servicios/signosVitales";
import Canalizacion from "./components/Servicios/Canalizacion";
import Heridas from "./components/Servicios/curacionHeridas";
import Puntos from "./components/Servicios/retiroPuntos";
import Sonda from "./components/Servicios/colocacionFoley";
import Medicamentos from "./components/Servicios/Medicamentos";
import Muestra from "./components/Servicios/Muestras";
import Glucometria from "./components/Servicios/Glucometria";
import Rehabilitacion from "./components/Servicios/Rehabilitacion";
import Nasogastrica from "./components/Servicios/Nasogastrica";

function App() {
  const [cargar, setCargar] = useState<boolean>(false);
  const [render, setRender] = useState<any>(null);
  let location = useLocation();

  useEffect(() => {
    setCargar(true);
    setRender(location.pathname !== ROUTES.ROOT ? <Header /> : <HeaderMain />);
    setTimeout(() => {
      setCargar(false);
    }, 500);
  }, [location.pathname]);

  const fondo = () => {
    return {
      minHeight: "100vh",
    };
  };

  return (
    <>
      {cargar && <Loading />}
      <main
        className="d-flex flex-column justify-content-between"
        style={fondo()}
      >
        {render}
        <div className={`flex-grow-1`}>
            <Routes>
              <Route path={ROUTES.ROOT} element={<Home />} />
              <Route path={ROUTES.TEAMWORK} element={<TeamWork />} />

              <Route path={ROUTES.VITALES} element={<Vitales />} />
              <Route path={ROUTES.CANALIZACION} element={<Canalizacion />} />
              <Route path={ROUTES.HERIDA} element={<Heridas />} />
              <Route path={ROUTES.PUNTOS} element={<Puntos />} />
              <Route path={ROUTES.SONDA} element={<Sonda />} />
              <Route path={ROUTES.MEDICAMENTO} element={<Medicamentos />} />
              <Route path={ROUTES.MUESTRA} element={<Muestra />} />
              <Route path={ROUTES.GLUCOMETRIA} element={<Glucometria />} />
              <Route path={ROUTES.TERAPIA} element={<Rehabilitacion />} />
              <Route path={ROUTES.NASOGASTRICA} element={<Nasogastrica />} />
            </Routes>
        </div>
        <WhatsApp />
        <Footer />
      </main>
    </>
  );
}

export default App;
