import { IlistInfo } from "../models/interfaces/IlistInfo";

import doctor from "../assets/images/objetoSocial/doctor.jpg"
import medicina from "../assets/images/objetoSocial/medicina.jpg"
import customer from "../assets/images/objetoSocial/customer.jpg"
import lecture from "../assets/images/objetoSocial/lecture.jpg"
import supplier from "../assets/images/objetoSocial/supplier.jpg"
import distribution from "../assets/images/objetoSocial/distribution.jpg"
import insurance from "../assets/images/objetoSocial/insurance.jpg"
import presentation from "../assets/images/objetoSocial/presentation.jpg"
import imparticion from "../assets/images/objetoSocial/imparticion.jpg"
import contract from "../assets/images/objetoSocial/contract.jpg"
import protect from "../assets/images/objetoSocial/protect.jpg"
import loan from "../assets/images/objetoSocial/loan.jpg"
import branch from "../assets/images/objetoSocial/branch.jpg"
import actos from "../assets/images/objetoSocial/actos.jpg"
import rent from "../assets/images/objetoSocial/rent.jpg"

export const listInfoData: IlistInfo[] = [
  {
    objeto: "El servicio a domicilio de cuidado de pacientes con enfermedades que requieren de atención constante parcial o las 24 horas del día. Esto incluye cuidados paliativos para ayudar a las personas con una enfermedad grave a sentirse mejor.",
    id: 1,
    icon: `${doctor}`,
    alt: "doctor"
  },
  {
    objeto: "La aplicación de medicamentos, canalizaciones, toma de glucometrías, toma de muestras para laboratorio, toma de pruebas para COVID, toma de signos vitales, curaciones y demás actividades propias de la profesión de enfermería y de la atención a pacientes.",
    id: 2,
    icon: `${medicina}`,
    alt: "medicina"
  },
  {
    objeto: "La contratación de servicios médicos para allegar a los pacientes la atención profesional requerida, cuando esto fuera necesario.",
    id: 3,
    icon: `${customer}`,
    alt: "customer"
  },
  {
    objeto: "La prestación de cualquier otro tipo de servicio de asistencia en tanto este sea tendiente a mejorar la calidad de vida de los pacientes y sus familiares.",
    id: 4,
    icon: `${lecture}`,
    alt: "lecture"
  },
  {
    objeto: "La compra, venta, consignación y distribución de insumos relacionados con la atención a pacientes, sus tratamientos y el equipo médico y diagnóstico que pudiera ser utilizado para restaurar la salud o mantener en mejores condiciones físicas, mentales, emocionales y espirituales a los pacientes y sus familias.",
    id: 5,
    icon: `${supplier}`,
    alt: "supplier"
  },
  {
    objeto: "La compra, venta, representación, distribución, consultoría, comercialización, producción y desarrollo, así como la importación y exportación de toda clase de productos relacionados con las ramas de la salud de la informática para la salud, equipos electrónicos o eléctricos para el mismo propósito.",
    id: 6,
    icon: `${distribution}`,
    alt: "distribution"
  },
  {
    objeto: "El establecimiento, organización, explotación, funcionamiento y administración de toda clase de negocios, comercios, laboratorios, talleres, centros de capacitación, franquicias y demás, en los que se manejen sistemas y procedimientos de atención, recuperación y mantenimiento de la salud.",
    id: 7,
    icon: `${insurance}`,
    alt: "insurance"
  },
  {
    objeto: "La prestación de toda clase de servicios de asesoría y consultaría en las propias ramas de la salud, la nutrición y el bienestar físico y mental.",
    id: 8,
    icon: `${presentation}`,
    alt: "presentation"
  },
  {
    objeto: "La impartición a terceros sean personas físicas o morales, de toda clase de cursos, seminarios, conferencias, mesas redondas, relacionados con este objeto social.",
    id: 9,
    icon: `${imparticion}`,
    alt: "imparticion"
  },
  {
    objeto: "Obtener, adquirir, utilizar, registrar o disponer de toda clase de patentes, marcas, licencias, certificados de invención, nombres comerciales, así como derechos sobre ellos y su comercialización, relacionados con el desarrollo en su totalidad de los objetos sociales de la empresa.",
    id: 10,
    icon: `${contract}`,
    alt: "contract"
  },
  {
    objeto: "Adquirir acciones, intereses, o tomar participación por los medios permitidos por la Ley, en el capital y patrimonio de todo género de sociedades mercantiles, civiles, asociaciones o empresas industriales, comerciales, de servicios.",
    id: 11,
    icon: `${protect}`,
    alt: "protect"
  },
  {
    objeto: "Tramitar ante toda clase de Instituciones de Crédito, Sociedades Financieras y demás correlativas, toda clase de préstamos o créditos con o sin garantía específica y otorgar toda clase de garantías y avales, de obligaciones o títulos de crédito.",
    id: 12,
    icon: `${loan}`,
    alt: "loan"
  },
  {
    objeto: "Establecer agencias o sucursales, despachos, dependencias, bodegas y domicilios convencionales en cualquier parte de la República Mexicana y el extranjero.",
    id: 13,
    icon: `${branch}`,
    alt: "branch"
  },
  {
    objeto: "En general, celebrar y realizar todos los actos, contratos y operaciones conexas, accesorias y accidentales permitidos por la Ley, ya sean civiles o mercantiles de cualquier naturaleza, que sean necesarios o convenientes para la realización de los objetos sociales.",
    id: 14,
    icon: `${actos}`,
    alt: "actos"
  },
  {
    objeto: "La adquisición por todos los medios permitidos por la Ley, así como el arrendamiento o subarrendamiento, de toda clase de vehículos, aparatos, equipo, y en general de aquellos bienes muebles e inmuebles que sean necesarios para el desarrollo de su objeto social.",
    id: 15,
    icon: `${rent}`,
    alt: "rent"
  }
];
