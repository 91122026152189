import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "../../styles/components/Loading.scss";

export default function Loading() {
  return (
    <Box sx={{ display: "flex" }} className="Loading">
      <CircularProgress color="success" className="loadingWidth" />
    </Box>
  );
}
