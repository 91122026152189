import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

import Title from "../TitleDerecha";
import { desarrolloData } from "../../constants/servicioData";

import "../../styles/components/Servicio.scss";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Servicio() {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);  

  return (
    <motion.div className="box Servicio" ref={ref} variants={boxVariant} initial="hidden" animate={control} >
      <Title titulo="Servicios" scroll={inView}/>
      <Container className="pt-5">
        <Row className="justify-content-between fondos" xl={4} lg={4} md={3} sm={2} xs={1}>
          {desarrolloData.map((data) => (
            <Col className="fondo m-1">
              <NavLink to={data.evento}>
                <Image src={data.imgCarta} alt={data.alt} width="100%" className={data.blur}/>
                <figcaption className="d-grid">
                  <div className="titulo">{data.titulo}</div>
                  <div className="texto">{data.subtitulo}</div>
                  <div className="servicio_boton">Conocer más</div>
                </figcaption>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Container>
    </motion.div>
  );
}