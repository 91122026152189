import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Container, Image, Navbar, Nav } from "react-bootstrap";
import { Box, Link } from "@mui/material";

import { ANCLAS, ROUTES } from "../../constants/routes";

import video from "../../assets/videos/main.mp4";
import logo from "../../assets/images/CuidArte.png";
import logoCuidArte from "../../assets/images/LogoCuidArte.png";

import "../../styles/components/Header.scss";

export default function HeaderMain() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    setNavbar(window.scrollY >= 50);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    let position = 0;
  
    const drawImage = (image, position) => {
      if (ctx && canvas) {
        // Configurar el tamaño del canvas
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        // Calcular las coordenadas y tamaño de la imagen
        const scale = Math.min(canvas.width / image.width, canvas.height / image.height) / 2;
        const imageWidth = image.width * scale;
        const imageHeight = image.height * scale;
        const x = position * (canvas.width - imageWidth) /1.04;
        const y = (canvas.height - imageHeight) / 1.05;

        // Dibujar la imagen en el lienzo
        ctx.drawImage(image, x, y, imageWidth, imageHeight);
      }
    };
  
    // Cargar la imagen que deseas dibujar
    const image = document.createElement('img');
    image.src = `${logoCuidArte}`; // Reemplaza con la ruta de tu imagen
  
    // animacion de transicion de la imagen
    image.onload = () => {        
      const animate = () => {
        position += 0.01; // Ajusta la velocidad de avance de la imagen
        drawImage(image, position);
  
        if (position < 1) {
          requestAnimationFrame(animate);
        }
      };
  
      animate();
    };

    const handleResize = () => {
      // Volver a dibujar la imagen cuando cambie el tamaño de la pantalla
      drawImage(image, position);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <div className='HeaderMain'>
      <Navbar className={`navmenu ${navbar ? "scroll" : ""} px-3`} expand="lg" variant="dark" fixed="top" collapseOnSelect>
        <Container fluid>
          <NavLink to={ROUTES.ROOT}>
            <Image src={logo} className='logo' alt="Logo CuidArte" height="100%"/>
          </NavLink>
          <Navbar.Toggle/>
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navmenu__nav-items">
              <Nav.Link as={NavLink} to={ROUTES.ROOT}>
                Inicio
              </Nav.Link>
              <Link href={ANCLAS.ENFERMERIA} color="inherit" className='nav-link servicio'>
                Enfermería
              </Link>
              <Link href={ANCLAS.CONOCENOS} color="inherit" className='nav-link servicio'>
                Quienes somos
              </Link>
              <Link href={ANCLAS.SOCIAL} color="inherit" className='nav-link servicio'>
                Objeto social
              </Link>
              <Link href={ANCLAS.SERVICIOS} color="inherit" className='nav-link servicio'>
                Servicio
              </Link>
              <Link href={ANCLAS.EQUIPOS} color="inherit" className='nav-link servicio'>
                Equipo en venta
              </Link>
              <Nav.Link as={NavLink} to={ROUTES.TEAMWORK}>
                Personal
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Box className="hero">
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <canvas ref={canvasRef} className="canvas" />
      </Box>
    </div>
  );
};