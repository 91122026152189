import React from "react";
import { Card } from "react-bootstrap";

import { IDesarrollo } from "../models/interfaces/IServicio";
import { ROUTES } from "../constants/routes";

import uno from "../assets/images/servicios/vitales.jpg";
import dos from "../assets/images/servicios/canalizacion.jpg";
import tres from "../assets/images/servicios/herida.jpg";
import cuatro from "../assets/images/servicios/puntos.jpg";
import cinco from "../assets/images/servicios/sonda.jpg";
import seis from "../assets/images/servicios/medicamento.jpg";
import siete from "../assets/images/servicios/muestra.jpg";
import ocho from "../assets/images/servicios/glucometria.jpg";
import nueve from "../assets/images/servicios/terapia.jpg";
import diez from "../assets/images/servicios/nasogastrica.jpg";

export const desarrolloData: IDesarrollo[] = [
  
  //Toma de signos vitales
  {
    idDesarrollo: 1,
    imgCarta: `${uno}`,
    alt: "Toma de Signos Vitales",
    titulo: "Toma de Signos Vitales",
    subtitulo: "Medición de parametros vitales.",
    texto: (
      <>
       <Card.Text className="text-justify">
          La toma de signos vitales es un procedimiento esencial en el ámbito de la atención médica que permite evaluar de manera rápida y precisa las funciones corporales básicas de un paciente.
        </Card.Text>
        <Card.Text className="text-justify">
          Estos signos incluyen la medición de la temperatura corporal, la frecuencia cardíaca, la frecuencia respiratoria, la presión arterial y oximetría.
        </Card.Text>
        <Card.Text className="text-justify">
          La toma regular de signos vitales es fundamental para el monitoreo de pacientes en diferentes entornos, desde hospitales hasta consultorios médicos, y es una herramienta crucial en la toma de decisiones clínicas.
        </Card.Text>
        <Card.Text className="text-justify">
          Los profesionales de la salud utilizan estos datos para evaluar el efecto de tratamientos, identificar signos tempranos de deterioro y ajustar la atención médica de manera apropiada.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.VITALES}`
  },

  //Canalización
  {
    idDesarrollo: 2,
    imgCarta: `${dos}`,
    alt: "Canalización",
    titulo: "Canalización",
    subtitulo: "Acceso venoso períferico.",
    texto: (
      <>
        <Card.Text className="text-justify">
          La canalización venosa periférica es un metodo para obtener un acceso vascular para administrar farmacos y perfundir liquídos, ya sea por goteo o infusión continua.
        </Card.Text>
        <Card.Text className="text-justify">
          La canalización se realiza con el objetivo de garantizar la absorción eficiente de sustancias necesarias para el tratamiento o el mantenimiento del paciente. 
        </Card.Text>
        <Card.Text className="text-justify">
          Este procedimiento es común en situaciones de emergencia, cirugías, cuidados intensivos y otras áreas médicas. La canalización también puede permitir la extracción de muestras de sangre para análisis diagnósticos.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.CANALIZACION}`
  },
  
  //Curación de Heridas
  {
    idDesarrollo: 3,
    imgCarta: `${tres}`,
    alt: "Curación de Heridas",
    titulo: "Curación de Heridas",
    subtitulo: "Tratamiento de lesiones en piel.",
    blur:"blur",
    texto: (
      <>
        <Card.Text className="text-justify">
          La curación de heridas es un proceso en la atención del paciente que implica la limpieza, desinfección y cierre de lesiones en la piel. El objetivo principal es promover la cicatrización y prevenir infecciones.
        </Card.Text>
        <Card.Text className="text-justify">
          El proceso de curación de heridas generalmente implica varios pasos. Primero, se limpia la herida para eliminar cualquier suciedad, tejido muerto y posibles agentes infecciosos. Luego, se aplica un agente desinfectante para prevenir la proliferación de bacterias.
        </Card.Text>
        <Card.Text className="text-justify">
          La curación de heridas es esencial para evitar complicaciones, promover la regeneración de tejido y minimizar la formación de cicatrices.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.HERIDA}`
  },

  //Retiro de puntos
  {
    idDesarrollo: 4,
    imgCarta: `${cuatro}`,
    alt: "Retiro de Puntos",
    titulo: "Retiro de Puntos",
    texto: (
      <>
        <Card.Text className="text-justify">
          El retiro de puntos es el procedimiento en el cual se eliminan puntos de sutura que se colocaron previamente para cerrar una herida o incisión. Estos puntos se utilizan para mantener los bordes de la piel juntos durante el proceso de cicatrización.
        </Card.Text>
        <Card.Text className="text-justify">
          El retiro de puntos generalmente se realiza cuando la herida ha sanado lo suficiente y los bordes de la piel están lo bastante adheridos. El proceso implica cortar o deshacer los nudos de las suturas y luego extraer suavemente los hilos de la piel.
        </Card.Text>
        <Card.Text className="text-justify">
          Una vez que los puntos son retirados, es crucial seguir cuidando la herida para asegurar una cicatrización completa y saludable. Dependiendo de la ubicación y el tipo de herida, el médico puede proporcionar pautas sobre la higiene y el cuidado apropiado después del retiro de los puntos.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.PUNTOS}`
  },

  //Colocación de Sonda Foley
  {
    idDesarrollo: 5,
    imgCarta: `${cinco}`,
    alt: "Colocación de Sonda Vesical",
    titulo: "Colocación de Sonda Vesical",
    subtitulo: "Colocación de sonda urinaria.",
    texto: (
      <>
        <Card.Text className="text-justify">
          La colocación de sonda vesical es un procedimiento en el cual se introduce una sonda urinaria en la vejiga a través de la uretra. Esta técnica se utiliza para drenar la orina de la vejiga cuando un paciente no puede hacerlo normalmente debido a diversas condiciones médicas.
        </Card.Text>
        <Card.Text className="text-justify">
          La colocación puede ser necesaria en situaciones como cirugías, lesiones en la médula espinal, problemas de próstata, retención urinaria o para facilitar la monitorización precisa de la producción de orina.
        </Card.Text>
        <Card.Text className="text-justify">
          Se debe llevar a cabo un cuidado adecuado para prevenir infecciones y garantizar el funcionamiento correcto. El médico o profesional de la salud proporcionará pautas sobre el manejo y el mantenimiento de la sonda.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.SONDA}`
  },

  //Aplicación de medicamentos
  {
    idDesarrollo: 6,
    imgCarta: `${seis}`,
    alt: "Aplicación de Medicamentos",
    titulo: "Aplicación de Medicamentos",
    subtitulo: "Administrar de medicamentos intravenosos, intramusculares, subcutoneos y vía oral.",
    texto: (
      <>
        <Card.Text className="text-justify">
          La aplicación de medicamentos se refiere al proceso de administrar productos farmacológicos a pacientes para tratar enfermedades, aliviar síntomas o prevenir complicaciones.
        </Card.Text>
        <Card.Text className="text-justify">
          La administración adecuada de medicamentos es fundamental para asegurar su efectividad y minimizar los efectos secundarios.
        </Card.Text>
        <Card.Text className="text-justify">
          Se lleva a cabo siguiendo pautas estrictas de seguridad y protocolos para prevenir errores.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.MEDICAMENTO}`
  },

  //Toma de muestras
  {
    idDesarrollo: 7,
    imgCarta: `${siete}`,
    alt: "Toma de Muestras",
    titulo: "Toma de Muestras",
    texto: (
      <>
        <Card.Text className="text-justify">
          La toma de muestras es el proceso de recolectar y obtener pequeñas porciones de tejido, fluidos corporales u otros materiales biológicos de un paciente para su posterior análisis y diagnóstico.
        </Card.Text>
        <Card.Text className="text-justify">
          Puede involucrar diferentes tipos de especímenes, como sangre, orina, saliva, tejido, secreciones, líquidos cefalorraquídeos, entre otros. Dependiendo del propósito del análisis, las muestras pueden ser examinadas en laboratorios clínicos para detectar infecciones, evaluar funciones orgánicas, identificar marcadores genéticos o analizar componentes químicos.
        </Card.Text>
        <Card.Text className="text-justify">
          Los profesionales de la salud deben seguir protocolos precisos para garantizar la integridad de las muestras y evitar la contaminación.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.MUESTRA}`
  },

  //Glucometría
  {
    idDesarrollo: 8,
    imgCarta: `${ocho}`,
    alt: "Glucometría",
    titulo: "Glucometría",
    subtitulo: "Medición de niveles de glucosa.",
    texto: (
      <>
        <Card.Text className="text-justify">
          La glucometría es el procedimiento de medir los niveles de glucosa en la sangre de una persona utilizando un dispositivo llamado glucómetro. Esta prueba se utiliza comúnmente para monitorear y controlar los niveles de azúcar en la sangre en personas con diabetes u otras condiciones médicas.
        </Card.Text>
        <Card.Text className="text-justify">
          El proceso de glucometría implica obtener una pequeña muestra de sangre, generalmente a través de una punción en el dedo, y luego aplicar esa muestra al glucómetro. El dispositivo proporciona una lectura rápida y precisa de los niveles de glucosa en la sangre.
        </Card.Text>
        <Card.Text className="text-justify">
          La glucometría permite a los pacientes controlar sus niveles de glucosa en diferentes momentos del día, como antes y después de las comidas o en situaciones específicas.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.GLUCOMETRIA}`
  },

  //Rehabilitación y Terapia Física
  {
    idDesarrollo: 9,
    imgCarta: `${nueve}`,
    alt: "Rehabilitación y Terapia Física",
    titulo: "Rehabilitación y Terapia Física",
    subtitulo: "Recuperación física integral.",
    texto: (
      <>
        <Card.Text className="text-justify">
          La rehabilitación y terapia física son enfoques fisioterapéuticos que se centran en ayudar a las personas a recuperar su función física, movilidad y calidad de vida después de lesiones, cirugías u otras condiciones médicas.
        </Card.Text>
        <Card.Text className="text-justify">
          Pueden incluir una variedad de técnicas y ejercicios personalizados según las necesidades de cada paciente.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.TERAPIA}`
  },

  //Colocación de Sonda Nasogástrica
  {
    idDesarrollo: 10,
    imgCarta: `${diez}`,
    alt: "Sonda Nasogástrica",
    titulo: "Sonda Nasogástrica",
    subtitulo: "Colocación de sonda gástrica.",
    texto: (
      <>
        <Card.Text className="text-justify">
          La colocación de sonda nasogástrica es un procedimiento en el cual se introduce una sonda de bajo calibre y flexible a través de la nariz o la boca de un paciente hacia el estómago.
        </Card.Text>
        <Card.Text className="text-justify">
          Las sondas nasogástricas son comúnmente utilizadas en situaciones donde el paciente no puede deglutir, por encontrarse en estado crítico o con ciertas condiciones médicas que requieren nutrición o control del contenido gástrico.
        </Card.Text>
      </>
    ),
    evento: `${ROUTES.NASOGASTRICA}`
  },
];