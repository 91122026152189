import verdad from "../assets/images/valores/Verdad.jpg"
import puntualidad from "../assets/images/valores/Puntualidad.jpg"
import honradez from "../assets/images/valores/Honradez.jpg"
import calidez from "../assets/images/valores/Calidez.jpg"
import empatia from "../assets/images/valores/Empatia.jpg"
import espiritu from "../assets/images/valores/Espiritu.jpg"
import compañerismo from "../assets/images/valores/Compañerismo.jpg"

export const valoresData = [
  {
    titulo:"Verdad",
    valor: "Bajo cualquier circunstancia, la verdad es el valor principal, pues nos permite saber de la manera más oportuna posible los problemas y buscar cómo resolverlos.",
    idValor: 1,
    img: `${verdad}`,
    alt:"Verdad"
  },
  {
    titulo:"Puntualidad",
    valor: "Debemos ser puntuales en nuestro trabajo, al llegar y al retirarnos, así como en el cumplimiento de los horarios de atención, medicación, toma de signos, etc.",
    idValor: 2,
    img: `${puntualidad}`,
    alt:"Puntualidad"
  },
  {
    titulo:"Honradez",
    valor: "Los pacientes y sus familias nos están confiando su casa y su patrimonio, no se tolerará ninguna desviación de una honradez impecable.",
    idValor: 3,
    img: `${honradez}`,
    alt:"Honradez"
  },
  {
    titulo:"Calidez",
    valor: "Los pacientes se encuentran en condición vulnerable y sus allegados también en cierta medida, parte de nuestro trabajo es hacerlos sentirse seguros en el tránsito hacia la salud.",
    idValor: 4,
    img: `${calidez}`,
    alt:"Calidez"
  },
  {
    titulo:"Empatía",
    valor: "Debemos ponernos en los zapatos del otro y pensar qué mas podemos hacer por ellos o, como Alejandra me dijo,: “Tratarlos como si fueran nuestros papás”.",
    idValor: 5,
    img: `${empatia}`,
    alt:"Empatia"
  },
  {
    titulo:"Espíritu de servicio",
    valor: "Antes que por el dinero, vamos por servir al prójimo. Desde luego que necesitamos ingresos, pero nunca debemos anteponer eso a la atención al paciente.",
    idValor: 6,
    img: `${espiritu}`,
    alt:"espiritu"
  },
  {
    titulo:"Compañerismo",
    valor: "Debemos ayudarnos unos a los otros bajo todas las circunstancias. Apoyarnos es lo que nos hace fuertes y lo que nos hará exitosos.",
    idValor: 7,
    img: `${compañerismo}`,
    alt:"Compañerismo"
  }
];