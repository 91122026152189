import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Col, Container, Row } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";

import Title from "../../components/TitleIzquierda";
import { directivoData } from "../../constants/teamworkData";

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 },
};

export default function Directivos(){
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);
  
  return(
    <motion.div className="box" ref={ref} variants={boxVariant} initial="hidden" animate={control} >
      <Title titulo="Directivos" scroll={inView}/>
      <Container className="pt-5 pb-5">
        {directivoData.map((data, index) => (
          <Row className={`align-items-center mb-2 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`} key={index}>
            <Col xl={6} lg={6} md={12} className={`text-center ${index % 2 === 0 ? 'order-lg-1 order-2' : 'order-lg-2 order-1'}`}>
              <img src={data.src} alt={data.alt} className="imagen" height="100%"/>
            </Col>
            <Col xl={6} lg={6} md={12} className="p-3 text-justify order-2">
              {data.descripcion.map((paragraph, i) => (
                <div key={i}>{paragraph}</div>
              ))}
            </Col>              
          </Row>        
        ))}        
      </Container>
    </motion.div>
  );
}